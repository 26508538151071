import React from 'react';
import Video from '../img/stela.mp4'

const Benefits = () => {
  return (
    <div id='Beneficii' className='container flex flex-col md:flex-row items-center px-10 xl:px-20 mx-auto mt-20 md:mt-32 xl:mt-32' >
        <div className='md:w-1/2'>
            <h2 className='max-w-xl text-4xl lg:text-5xl text-left font-Quattrocento'>Inovație și Transformare: Masajul Facial Sculptural Bucal</h2>
              <div className='flex md:hidden justify-center md:w-1/2 mt-10 md:mt-0'>
                <video width="286" height="500" controls  frameborder="0">
                  <source src={Video} type="video/mp4"/>
              </video>
              </div>
            <p className=' max-w-xl mt-10 text-lg xl:text-xl text-left text-black font-Quicksand'>Este o tehnică avansată de masaj facial, concentrată în special pe zona buzelor, obrajiilor și gurii, care are scopul de a sculpta și întineri aspectul feței. Această tehnică de masaj este creată pentru a îmbunătăți contururile feței și a atenua semnele îmbătrânirii.</p>
            <p className=' max-w-xl mt-5 text-lg xl:text-xl text-left text-black font-Quicksand'> Tehnicile folosite în acest tip de masaj pot ajunge la straturi mai profunde ale pielii și ale mușchilor, având un efect mai profund și mai durabil asupra aspectului feței. Eu o consider o alternativă naturală și mult mai ieftină a injecțiilor cu botox.</p>
            <p className=' max-w-xl mt-5 text-lg xl:text-xl text-left text-black font-Quicksand'> Datorită accentului pe modelarea feței și îmbunătățirea conturului, rezultatele masajului facial sculptural bucal pot fi mai vizibile și mai rapide decât în cazul altor masaje.</p>
        </div>
        <div className='hidden md:flex justify-center md:w-1/2 mt-10 md:mt-0'>
              <video width="286" height="500" controls  frameborder="0">
                  <source src={Video} type="video/mp4"/>
              </video>
        </div>
    </div>
  )
}

export default Benefits