import React from 'react'

const ChatBot = () => {
  return (
    <div>
        <iframe style={{width:300, height:600, border:'none', borderRadius:10, overflow:'auto', position:'fixed', bottom:10, right:10}} src="https://steli.vercel.app" title="Chatbot"></iframe>
    </div>
  )
}

export default ChatBot