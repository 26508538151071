import React from 'react';
import Clienta1 from '../img/clienta1.png';
import Clienta2 from '../img/clienta2.png';
import Clienta3 from '../img/client3.png';
import Rating from './Rating';

const Testimonials = () => {
  return (
    <div  className='container px-5 xl:px-20 mx-auto mt-20 md:mt-32 mb-20 md:mb-40'>
        <div>
        <h2 className='max-w-2xl text-4xl lg:text-5xl text-left font-Quattrocento mt-10'>Ce Spun Clientele</h2>
        </div>
        <div className='flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-10 mt-10 md:mt-20 mx-auto font-Quicksand'>
            <div className=' basis-1/3 border-gray border-2 p-5 rounded-3xl text-black '>
                <p className='text-lg'>Recomand, este un masaj foarte eficient!</p>
                <div className='flex flex-row pt-10 items-center space-x-5'>
                    <img src={Clienta3} className='' alt="Fotografie tip portret a Ioanei Dragomir" />
                    <div>
                        <p className='font-bold'>Ioana Dragomir</p>
                        <Rating/>
                    </div>

                </div>
            </div>
            <div className=' basis-1/3 border-gray border-2 p-5 rounded-3xl text-black '>
                <p className='text-lg'>Este super, recomand cu încredere.</p>
                <div className='flex flex-row pt-10 items-center space-x-5'>
                    <img src={Clienta2} className='' alt="Fotografie de la distanță a Elenei Brezoi" />
                    <div>
                        <p className='font-bold'>Brezoi Elena</p>
                        <Rating/>
                    </div>
                </div>
            </div>
            <div className=' basis-1/3 border-gray border-2 p-5 rounded-3xl text-black '>
                <p className='text-lg'>Recomand cu toată încrederea acest masaj facial sculptural!</p>
                <div className='flex flex-row pt-5 items-center space-x-5'>
                    <img src={Clienta1} className='' alt="Fotografie tip portret a Georgetei Gorgan" />
                    <div>
                        <p className='font-bold'>Georgeta Gorgan</p>
                        <Rating/>
                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}

export default Testimonials