import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Privacy from './pages/Privacy';
import Cookie from './pages/Cookie';
import Credits from './pages/Credits';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "Privacy",
    element: <Privacy/>,
  },
  {
    path: "Cookie",
    element: <Cookie/>,
  },
  {
    path: "Credits",
    element: <Credits/>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

