import React from 'react';
import NavbarSecond from '../components/NavbarSecond';
import CookiePolicy from '../components/CookiePolicy';
import Footer from '../components/Footer';

const Cookie = () => {
  return (
    <div>
        <NavbarSecond/>
        <CookiePolicy/>
        <Footer/>
    </div>
  )
}

export default Cookie