import React from 'react'

const Faq = () => {
  return (
    <div>
        <div id='Faq' className='faq-img'>
            <div className="container flex flex-col md:flex-row mx-auto px-8 xl:px-20 pt-20 md:pt-32 pb-20 md:pb-32 space-x-4 md:space-x-12">
                <div className='mb-10 md:mb-0'>
                    <h2 className='max-w-lg text-4xl text-center lg:text-5xl md:text-left font-Quattrocento'>Cele mai frecvente întrebări</h2>
                    <p className='max-w-md text-center text-lg pt-8 md:text-left text-black font-Quicksand'>Dacă întrebarea ta nu se regăsește aici, nu ezita să mă contactezi pentru a primi răspunsuri personalizate. Sunt aici pentru a te ajuta să înțelegi mai bine cum masajul facial sculptural bucal poate contribui la frumusețea și încrederea ta.</p>
                </div>
                <div className=' space-y-10 md:space-y-20'>
                    <div className="flex flex-col md:flex-row mx-auto space-y-8 md:space-y-0 md:space-x-10">
                        <div>   
                            <h3 className='font-bold text-xl max-w-sm pb-2 font-Quicksand' >Când pot vedea rezultatele masajului facial sculptural bucal?</h3>
                            <p className='max-w-md text-lg font-Quicksand '>Mulți clienți observă îmbunătățiri imediate în aspectul pielii după o singură sesiune de masaj facial sculptural bucal. Linii fine pot fi estompate, contururile pot deveni mai definite, iar pielea poate avea un aspect mai strălucitor.</p>
                        </div>
                        <div>
                            <h3 className='font-bold text-xl max-w-sm pb-2 font-Quicksand' >Cât durează o sesiune de masaj facial sculptural bucal?</h3>
                            <p className='max-w-md text-lg font-Quicksand ' >Durata unei sesiuni de masaj facial sculptural bucal poate varia în funcție de nevoile tale specifice și de pachetul ales. În general, sesiunile pot dura între 30 și 60 de minute.</p>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row mx-auto space-y-8 md:space-y-0 md:space-x-10">
                        <div>
                            <h3 className='font-bold text-xl max-w-sm pb-2 font-Quicksand' >Există contraindicații pentru acest masaj?</h3>
                            <p className='max-w-md text-lg font-Quicksand ' > 
                                Masajul facial sculptural bucal este în general sigur și non-invaziv. Cu toate acestea, există contraindicații în cazuri ca afecțiuni dermatologice severe, răni deschise sau infecții în zona feței, sau anumite condiții medicale. Înainte de a începe o sesiune, vom discuta cu tine despre starea ta de sănătate pentru a ne asigura că masajul este potrivit pentru tine.</p>
                        </div>
                        <div>
                            <h3 className='font-bold text-xl max-w-sm pb-2 font-Quicksand' >Cât de des ar trebui să fac masaj facial sculptural bucal?</h3>
                            <p className='max-w-md text-lg font-Quicksand ' > În general, pentru a vedea rezultate semnificative pe termen lung, este recomandat să urmezi o serie inițială de sesiuni regulate, de exemplu, o dată pe săptămână sau o dată la două săptămâni.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Faq