import React from 'react';
import Stela from '../../src/img/stela.webp';

import { Link } from 'react-scroll';

const About = () => {
  return (
    <div className='container flex flex-col-reverse md:flex-row items-center px-10 xl:px-20 mx-auto mt-20 md:mt-32 mb-20 md:mb-40 md:space-x-20 xl:space-x-0' >
        <div className='md:w-1/2'>
            <img src={Stela} alt="Stela în timp ce zâmbește, deținătoarea salonului StelMina" className='hidden md:flex' />
        </div>
        <div className='md:w-1/2'>
            <h2 className='max-w-2xl text-4xl text-center lg:text-5xl md:text-left font-Quattrocento mb-10 md:mb-0 mt-10'>Bine ai venit la salon StelMina</h2>
            <img src={Stela} alt="Stela în timp ce zâmbește, deținătoarea salonului StelMina"  className='flex md:hidden'/>
            <p className=' max-w-xl mt-5 text-lg xl:text-xl text-left text-black font-Quicksand'>Numele meu este Stela și cu o pasiune dedicată artei masajului facial, sunt aici pentru a-ți oferi o experiență de neuitat. Cu ani de experiență și formare specializată, sunt devotată să îmbunătățesc aspectul feței tale prin metode non-invazive și terapeutice. Fiecare sesiune este personalizată pentru a se potrivi nevoilor unice ale pielii tale.</p>
            <p className=' max-w-xl mt-5 mb-10 text-lg xl:text-xl text-left text-black font-Quicksand'>Sunt nerăbdătoare să te cunosc și să începem călătoria spre o piele mai radiantă și o încredere crescută în frumusețea ta naturală. Pentru întrebări, programări sau pentru a discuta cum pot personaliza un tratament pentru tine, te invit să mă contactezi.</p>
            <Link to="Contact" spy={true} smooth={true} offset={-100} duration={500} className=' text-lg p-3 mt-10 px-6 pt-2 text-white bg-accent-coral-pink rounded-full baseline hover:text-accent-coral-pink hover:bg-white hover:border-2 hover:border-accent-coral-pink '>Programează un masaj</Link>

        </div>
    </div>
  )
}

export default About