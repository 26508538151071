import React from 'react';
import NavbarSecond from '../components/NavbarSecond';
import Footer from '../components/Footer';

import PrivacyPolicy from '../components/PrivacyPolicy';

const Privacy = () => {

return (
    <div>
        <NavbarSecond/>
        <PrivacyPolicy/>
        <Footer/>
    </div>
  )
}

export default Privacy