import React from 'react';
import faceMassage from '../../src/img/benefits.webp'

const BenefitsSecond = () => {
  return (
    <div className='container flex flex-col-reverse md:flex-row items-center px-10 mx-auto mt-20 md:mt-40 md:space-x-10' >
        <div className='md:w-1/2 hidden md:block'>
            <img src={faceMassage} alt="" />
        </div>
        <div className='md:w-1/2'>
            <h2 className='max-w-xl text-4xl lg:text-5xl text-left font-Quattrocento'>Beneficii Unice ale Masajului Sculptural</h2>
              <div className='block md:hidden'>
                <img src={faceMassage} alt="Femeie căreia i se face un masaj facial în timp ce stă întinsă și se relaxează" />
              </div>
            <p className=' max-w-xl mt-5 text-lg xl:text-xl text-left text-black font-Quicksand'>Pășește într-o lume a transformării și frumuseții autentice, descoperind avantajele inegalabile ale masajului facial sculptural bucal. De la un plus de elasticitate la reducerea vizibilă a ridurilor, această tehnică aduce o imediată schimbare pielii tale.</p>
            <ul className='mt-10 text-lg xl:text-xl font-bold font-Quicksand'>
                <li className=''>1. Micșorează bărbia dublă, conturează fața și gâtul</li>
                <li className='mt-2'>2. Crește elasticitatea pielii prin producția de colagen și elastină</li>
                <li className='mt-2'>3. Reduce semnificativ ridurile și liniile fine</li>
                <li className='mt-2'>4. Relaxează profund și detensionează întregul organism</li>
                <li className='mt-2'>5. Tonifiază și întărește mușchii faciali</li>
                <li className='mt-2'>6. Rezultate vizibile în cel mai scurt timp</li>
            </ul>
        </div>
    </div>
  )
}

export default BenefitsSecond