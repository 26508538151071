import React from 'react';
import ex1 from '../../src/img/ex1.png';
import ex2 from '../../src/img/ex2.png';
import ex3 from '../../src/img/ex3.png';


const Results = () => {
  return (
    <div id='Rezultate' className='bg-background-coral-pink pt-20 pb-20 md:pt-32 md:pb-32 mt-20 md:mt-32'>
        <div className='container items-center px-10 md:px-20 lg:px-64 mx-auto' >
            <h2 className='text-4xl text-center lg:text-5xl font-Quattrocento'>Înainte și După Masajul Facial Sculptural Bucal</h2>
            <p className=' mt-5 text-center text-lg xl:text-xl text-black font-Quicksand'> Aceste fotografii subliniază transformările subtile, dar evidente, pe care le poți obține după o singură ședință. Contururi împrospătate, o piele mai radiantă și o senzație generală de bine.</p>
        </div>
        <div className='container items-center lg:items-start flex flex-col lg:flex-row px-10 mt-20 mx-auto'>
            <div>
                <img src={ex1} alt="Înainte și după masajul facial sculptural cu un zoom pe partea stângă a feței unei cliente acolo unde există riduri" />
                <div className=' mx-6 mb-10 md:mb-0 md:mx-12'>
                    <h3 className='font-Quicksand font-bold text-xl mb-2' >Linii Fine Estompate</h3>
                    <p className='max-w-sm text-lg font-Quicksand' >În urma unei singure ședințe clienta mea a putut vedea o schimbare radicală a liniilor fine din jurul gurii.</p>
                </div>
            </div>
            <div>
                <img src={ex2} alt="Înainte și după masajul facial sculptural cu un zoom pe ochii unei cliente acolo unde există riduri și pleoape ferme" className='md:mt-12 lg:mt-0' />
                <div className='mx-6 mb-10 md:mb-0 md:mx-12'>
                    <h3 className='font-Quicksand font-bold text-xl mb-2' >Piele Mai Fermă</h3>
                    <p className='max-w-sm text-lg font-Quicksand' >Pleoape mai ferme și liniile fine din jurul ochilor estompate semnificativ, oferind un aspect proaspăt și odihnit ochilor. </p>
                </div>
            </div>
            <div>
                <img src={ex3} alt="Înainte și după masajul facial sculptural cu un zoom pe partea stângă a feței unei cliente acolo unde există riduri adânci ale feței" className='md:mt-12 lg:mt-0' />
                <div className='mx-6 mb-10 md:mb-0 md:mx-12'>
                    <h3 className='font-Quicksand font-bold text-xl mb-2' >Transformare Profundă a Feței</h3>
                    <p className='max-w-sm text-lg font-Quicksand' >Observă cum linii adânci și definite dinaintea masajului aproape că dispar în urma sesiunii de masaj facial sculptural bucal, făcând clienta mult mai fericită.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Results