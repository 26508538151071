import React, { useState, useEffect } from 'react'
import '../index.css';
import HamburgerOn from '../img/hamburger-on.svg';
import HamburgerOff from '../img/hamburger-off.svg';
import {Link} from 'react-router-dom';
import Logo from '../img/Logo.png';

const NavbarSecond = () => {
  const [isHamburgerClicked, setIsHamburgerClicked] = useState(false);

  const handleClick = () => {
    setIsHamburgerClicked(!isHamburgerClicked);
  }
  const [navColor, setnavColor] = useState("transparent");
  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavColor("#E9E9E9") : setnavColor("transparent");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);


  
  return (
    <nav className='sticky top-0' style={{ backgroundColor: navColor, transition: "all 1s"}}>
    <div className=' container mx-auto p-5 xl:px-20 font-Quattrocento'>
        <div className="flex items-center justify-between">
            <div className='flex'>
            <Link to="/" spy={true} smooth={true} offset={-100} duration={500} className=' cursor-pointer '>
                <img src={Logo} alt='Logo-ul StelMina care conține un fundal roz peste care apare un desen al unei fețe și textul StelMina Beauty' className=' h-14 w-14 lg:h-24 lg:w-24'></img>
            </Link>
            <div className="hidden lg:flex items-center space-x-8 text-lg pl-10">
                <Link to="/" spy={true} smooth={true} offset={-100} duration={500} className='hover:text-accent-coral-pink text-lg cursor-pointer'>Acasă</Link>
            </div>
            </div>
            <div className='hidden lg:block p-3 px-6 text-2xl underline'>
                <p>+07 23 489 562</p>
            </div>

            <button id="menu-btn" className="block hamburger lg:hidden focus:outline-none" onClick={handleClick}>
                {isHamburgerClicked ? <img src={HamburgerOff} alt="" /> : <img  src={HamburgerOn} alt='' />}
            </button>

        </div>

        <div className='lg:hidden'>
          {isHamburgerClicked ? 
          
          <div id="menu" className="absolute flex flex-col px-10 self-end py-8 mt-10 space-y-6  bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md font-Quicksand ">
                <Link to="Acasa" spy={true} smooth={true} offset={-100} duration={500} className='hover:text-accent-coral-pink text-lg cursor-pointer'>Acasă</Link>
                <div className='block font-bold lg:hidden text-lg underline font-Quicksand'>
                  <p>+07 23 489 562</p>
                </div>
          </div>
          : <></>}
        </div>
    </div>
    </nav>
  )
}

export default NavbarSecond