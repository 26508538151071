import CookieConsent from "react-cookie-consent";

import React from 'react'

const CookieBanner = () => {
  return (
    <div>
        <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#E9E9E9", color: 'black' }}
            buttonStyle={{ color: "white    ", fontSize: "15px", background: '#D68F80' }}
            expires={150}
            >
            Acest site utilizează cookie-uri pentru a îmbunătăți experiența utilizatorului. Vedeți ce fel de cookies folosim în <a href="#cookies" className=" text-accent-coral-pink"> Politica Cookie.</a>{" "}
        </CookieConsent>
    </div>
  )
}

export default CookieBanner