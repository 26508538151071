import React from 'react'

const Services = () => {
  return (
    <div id='Servicii' className='container px-5 xl:px-20 mx-auto mt-24 md:mt-32 mb-20 md:mb-32 md:space-x-10'>
        <div className='container items-center px-5 md:px-20 xl:px-60 mx-auto' >
            <h2 className='text-4xl text-left md:text-center lg:text-5xl font-Quattrocento'>Servicii Pentru O Îngrijire Completă</h2>
            <p className=' mt-5 text-left md:text-center text-lg xl:text-xl text-black font-Quicksand'>Bun venit în universul înfrumusețării, unde fiecare serviciu este o invitație să te răsfeți și să te simți în largul tău. Aici ai o selecție de servicii create cu dragoste și atenție, pentru ca tu să te simți minunat în pielea ta, în fiecare zi.</p>
        </div>
        <div className="container flex flex-col lg:flex-row px-5 md:px-10 mt-10 mx-auto space-y-10 md:space-y-10 lg:space-y-0 lg:space-x-12 lg:mt-32">
            <div className='basis-1/3 border-gray border-2 p-5 rounded-3xl text-black'>
                <h3 className='font-Quicksand font-bold text-xl mb-2'><span className="text-accent-coral-pink">1.</span>  Tratament Facial Basic</h3>
                <p className='text-lg'>Descoperă esența îngrijirii cu tratamentul facial basic. O experiență delicată și personalizată care curăță, hidratează și tonifică pielea, oferindu-i un aspect proaspăt și revigorat.</p>
            </div>
            <div className='basis-1/3  border-gray border-2 p-5 rounded-3xl text-black'>
                <h3 className='font-Quicksand font-bold text-xl mb-2'> <span className="text-accent-coral-pink">2.</span> Tratament Facial cu Ultrasunete</h3>
                <p className='text-lg'>Adu-ți pielea la nivelul următor cu tratamentul facial cu ultrasunete. Tehnologia avansată curăță în profunzime și îmbunătățește textura pielii, lăsându-ți tenul radiant și catifelat.</p>
            </div>
            <div className='basis-1/3  border-gray border-2 p-5 rounded-3xl text-black'>
                <h3 className='font-Quicksand font-bold text-xl mb-2'><span className="text-accent-coral-pink">3.</span>   Microdermabraziune</h3>
                <p className='text-lg'>Această tehnică delicată elimină celulele moarte și îmbunătățește elasticitatea pielii, lăsându-ți chipul mai neted și proaspăt. Elimină punctele negre, urmele de acnee și cicatriciile superficiale.</p>
            </div>
        </div>
        <div className="container flex flex-col lg:flex-row px-5 md:px-10 mt-10 space-y-10 md:space-y-10 lg:space-y-0 lg:space-x-12 lg:mt-20">
            <div className='basis-1/3  border-gray border-2 p-5 rounded-3xl text-black'>
                <h3 className='font-Quicksand font-bold text-xl mb-2'><span className="text-accent-coral-pink">4.</span>   Masaj Facial Personalizat</h3>
                <p className='text-lg'>Masajul facial este poate cel mai relaxant tratament facial. Previne apariția ridurilor și ajută la eliminarea toxinelor printr-o mai bună oxigenare a tenului, diminuează cearcănele și pungile de sub ochi.</p>
            </div> 
            <div className='basis-1/3  border-gray border-2 p-5 rounded-3xl text-black'>
                <h3 className='font-Quicksand font-bold text-xl mb-2'><span className="text-accent-coral-pink">5.</span>  Masaj Bucal</h3>
                <p className='text-lg'> Masajul bucal contribuie la circulația sângelui și stimulează producția de colagen, îmbunătățind astfel aspectul și fermitatea pielii din jurul gurii.</p>
            </div>
            <div className='basis-1/3  border-gray border-2 p-5 rounded-3xl text-black'>
                <h3 className='font-Quicksand font-bold text-xl mb-2'><span className="text-accent-coral-pink">6.</span>  Make-up și epilare</h3>
                <p className='text-lg'>Completează-ți look-ul cu make-up de înaltă calitate, creat special pentru a sublinia frumusețea ta naturală. Descoperă de asemenea și senzația de piele catifelată prin epilarea feței dar și a corpului.</p>
            </div>
        </div>
    </div>
  )
}

export default Services