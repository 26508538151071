import React from 'react';
import Logo from '../img/Logo.png';
import { Link } from 'react-router-dom';
import ChatBot from './ChatBot';

const Footer = () => {
  return (
    <div className='container flex flex-col md:flex-row  px-10 xl:px-20 mx-auto mt-20 pb-20 justify-between '>
        <div className='flex flex-col basis-1/2 mr-10'>
            <Link to="/" spy={true} smooth={true} offset={-100} duration={500} className=' cursor-pointer '>
                <img src={Logo} alt='Logo-ul StelMina care conține un fundal roz peste care apare un desen al unei fețe și textul StelMina Beauty' className=' h-14 w-14'></img>
            </Link>
            <p className='text-md mt-2 font-Quicksand max-w-sm md:max-w-xs'>Răsfăț total pentru suflet și piele la StelMina Beauty Salon - Redescoperă-ți frumusețea naturală</p>
            <p className='text-sm text-gray-700 font-Quicksand mt-2 md:mt-10'>StelMina Beauty @ 2023. Toate drepturile rezervate.</p>
        </div>

        <div className='flex flex-col basis-1/3 mt-5 md:mt-0'>
            <h4 className='text-lg font-bold font-Quicksand'>GDPR</h4>
            <Link to='/Credits' className='text-md mt-2 font-Quicksand '>Credits</Link>
            <Link to='/Cookie' className='text-md mt-2 font-Quicksand '>Politică cookie</Link>
            <Link to='/Privacy' className='text-md mt-2 font-Quicksand '>Politică de confidențialitate</Link>
        </div>
        <div className='flex flex-col basis-1/3 mt-5 md:mt-0'>
            <h4 className='text-lg font-bold font-Quicksand'>Legal</h4>
            <Link to='https://anpc.ro/ce-este-sal/' target='_' className='text-md mt-2 font-Quicksand '>ANPC</Link>
            <Link to='https://www.dataprotection.ro/' target='_' className='text-md mt-2 font-Quicksand '>ANSPDCP</Link>
        </div>
        <div className='flex flex-col basis-1/3 mt-5 md:mt-0'>
            <h4 className='text-lg font-bold font-Quicksand'>Orar</h4>
            <p className='text-md mt-2 font-Quicksand '>Marți - Vineri: 10:00 - 18:00</p>
            <p className='text-md mt-2 font-Quicksand '>Sâmbătă: 10:00 - 14:00</p>
            <p className='text-md mt-2 font-Quicksand '>Duminică și Luni: Închis</p>
        </div>
        <ChatBot/>

    </div>
  )
}

export default Footer