import React from 'react'

const CreditsPage = () => {
  return (
    <div className='container items-center px-10 xl:px-20 mx-auto mt-10 mb-20 font-Quicksand'>
        <h1 className='max-w-2xl text-4xl lg:text-5xl text-left mt-10'>Credits</h1>
        <br />
        <p className='text-lg'>Această pagină a fost creată pentru a oferi creditul de drept al persoanelor care au creat anumite fotografii și iconițe de pe acest site.</p>
        <br />
        Photo by <a href="https://unsplash.com/@natinati?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Nati Melnychuk</a> on <a href="https://unsplash.com/photos/I-6Ap7JXHq8?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
        <br />
        Photo by <a href="https://www.pexels.com/@shiny-diamond/">Shiny Diamond</a> on <a href="https://www.pexels.com/photo/woman-smiling-while-cleaning-her-face-3762457/">Pexels</a>
        <br />
        <a href="https://www.flaticon.com/free-icons/phone" title="phone icons">Phone icons created by Gregor Cresnar - Flaticon</a>
        <br />
        <a href="https://www.flaticon.com/free-icons/email" title="email icons">Email icons created by Freepik - Flaticon</a>
        <br />
        <a href="https://www.flaticon.com/free-icons/pin" title="pin icons">Pin icons created by Those Icons - Flaticon</a>
        <br />
        <a href="https://www.flaticon.com/free-icons/facebook" title="facebook icons">Facebook icons created by Freepik - Flaticon</a>
    </div>
  )
}

export default CreditsPage