import React from 'react';
import Mail from '../img/mail.png';
import Phone from '../img/phone.png';
import Location from '../img/pin.png';
import Facebook from '../img/facebook.png';

const Contact = () => {
  return (
    <div id='Contact' className='container flex flex-col md:flex-row items-center px-10 xl:px-20 mx-auto mt-20 md:mt-32 mb-20 md:mb-32 md:space-x-10' >
        <div className='md:w-1/2 mb-10'>
        <h2 className='max-w-2xl text-4xl lg:text-5xl text-left font-Quattrocento mt-10'>Începe Drumul Spre Transformare: Programează-te Astăzi</h2>
        <p className=' max-w-xl mt-5 text-lg xl:text-xl text-left text-black font-Quicksand'>Sunt aici pentru tine în fiecare etapă a călătoriei tale către o frumusețe mai strălucitoare și încredere sporită în tine. Împărtășesc cu entuziasm expertiza mea în masajul facial sculptural bucal, dar și în celelalte servicii pe care le ofer.</p>
        <p className=' max-w-xl mt-5 text-lg xl:text-xl text-left text-black font-Quicksand'>Indiferent dacă ai întrebări, dorești să afli mai multe despre beneficiile masajului sau să programezi o ședință, te invit să intri în contact cu mine. Răspund cu drag la orice întrebare și ofer toate detaliile necesare.</p>
        <p className=' hidden md:flex max-w-xl mt-5 text-lg xl:text-xl text-left text-black font-Quicksand'>Ține minte că frumusețea ta merită cele mai bune tratamente și soluții pentru ca tu să te simți minunat.</p>

        </div>
        <div className='md:w-1/2 flex justify-center'>
            <div className="flex flex-col space-y-10 items-start">
                <div className='flex flex-row space-x-5'>
                    <img src={Phone} alt="Iconița unui telefon roz" className='h-8 w-8' />
                    <p className='text-lg font-Quicksand font-bold' >+07 22 235 112</p>
                </div>
                <div className='flex flex-row space-x-5'>
                    <img src={Mail} alt="Iconița email roz" className='h-8 w-8'/>
                    <p className='text-lg font-Quicksand' >smaftei55@gmail.com</p>
                </div>
                <div className='flex flex-row space-x-5'>
                    <img src={Location} alt="Iconiță locație roz" className='h-8 w-8'/>
                    <p className='text-lg font-Quicksand' >Str. CAZARMII NR 3, Caransebes, Romania</p>
                </div>
                <div className="flex flex-row space-x-5">
                <img src={Facebook} alt="Iconiță Facebook" className='h-8 w-8'/>
                <a href='https://www.facebook.com/salonstela/' target='_' className='text-lg font-Quicksand' >Facebook</a>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default Contact